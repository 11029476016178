import React, { useEffect, createContext, useContext, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Divider, Layout, Menu, Spin, Typography, Tag } from 'antd';
import axios from 'axios';
import { AuthContext, NavBarContext, AuthInfoContext } from '../App';

const { Title, Text, Paragraph } = Typography;
function Home() {
  const navigate = useNavigate();
  const {isAuthenticated, setIsAuthenticated, authFailReason, setAuthFailReason, verifyAuth} = useContext(AuthContext);
  const {toggleNavBarVisibility, setSelectedKey, selectedKey} = useContext(NavBarContext);
  const { authInfo, setAuthInfo } = useContext(AuthInfoContext);

  useEffect(() => {
    verifyAuth();
    setSelectedKey('1');
    toggleNavBarVisibility(true);
    return () => {
      toggleNavBarVisibility(true);
    };
  }, []);
  return (
    <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left', height: '100%' }}>
      <div style={{ textAlign: 'left', width: '90%' }}>
        <Title level={1} style={{ marginTop: '0em' }}>Welcome to SteadyShuttle.</Title>
        {/* <Tag style={{marginTop:"-em"}}>Beta</Tag> */}
        <Title level={5}>Use the navigation bar on the top to access the different modules.</Title>
        <Divider/>
        <Title level={3}>Get started with these steps.</Title>
        <Paragraph>1. Create a passenger.</Paragraph>
        <Paragraph>2. Create a booking for specified passenger.</Paragraph>
        <Paragraph>3. Wait for approval (you'll receive a notification)</Paragraph>
        <Paragraph>4. Make payment for booking (only PayNow accepted)</Paragraph>
        <Paragraph>5. Wait for booking to be processed.</Paragraph>
        <Paragraph>6. Receive confirmation via Telegram.</Paragraph>
        <Paragraph>[!] Unsuccessful booking? Refunds will be processed automatically within an hour from the departure time.</Paragraph>
        <Divider/>
        <Title level={4}>Still in doubt?</Title>
        <Paragraph><Link to="/faq">Learn more in our FAQs</Link> or <Link to="/support">reach out to us directly</Link>.</Paragraph>
      </div>
    </div>
  )
}

export default Home