import React, { useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Layout, Menu, Spin, Button, Typography } from 'antd';
import axios from 'axios';
import { AuthContext, NavBarContext, AuthInfoContext } from '../App';

const { Title, Text, Paragraph } = Typography;

function Support() {
  const navigate = useNavigate();
  const {
    isAuthenticated,
    setIsAuthenticated,
    authFailReason,
    setAuthFailReason,
    verifyAuth,
  } = useContext(AuthContext);
  const { toggleNavBarVisibility, setSelectedKey, selectedKey } =
    useContext(NavBarContext);
  const { authInfo, setAuthInfo } = useContext(AuthInfoContext);

  useEffect(() => {
    verifyAuth();
    setSelectedKey('5');
    toggleNavBarVisibility(true);
    return () => {
      toggleNavBarVisibility(true);
    };
  }, []);

  return (
    <div style={{ textAlign: 'left' }}>
      <Title style={{ marginTop: "0em" }}>Need help?</Title>

      <Paragraph>
        <Text>
          If your solution can't be found in our{' '}
          <Link to="/faq">
            <Text type="secondary" strong>
              frequently asked questions
            </Text>
          </Link>
          , you may reach out to us.
        </Text>
      </Paragraph>

      <Paragraph>
        <Text>
          You can reach us through the below Telegram user. As this chat is
          human-operated, please give us some time to reply.
        </Text>
      </Paragraph>

      <Paragraph>
        <Text>
        Our customer support team is here to assist you during the <b>weekdays from 10am to 5pm</b>. Kindly note that <b>our team may not be accessible over the weekends</b>. Nevertheless, rest assured that we will get back to your queries on Monday morning.
        </Text>
      </Paragraph>

      {/* <Paragraph>
        <Text>
          To help us assist you better, please quote your account number when contacting us: 
          <Title level={3} style={{ display: 'inline', marginLeft: '0.3em' }}>
            {localStorage.getItem('cid')}
          </Title>
        </Text>
      </Paragraph> */}

      <Button
        onClick={() => {
          // open tg resolve
          window.location.href = 'tg://resolve?domain=steadyshuttlesupport';
        }}
      >
        Contact Live Chat via Telegram
      </Button>
    </div>
  );
}

export default Support;
