import React, { useEffect, createContext, useContext, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Alert, Layout, Menu, Spin } from 'antd';
import axios from 'axios';
import Home from './pages/Home';
import Support from './pages/Support';
import Bookings from './pages/Bookings';
import FAQ from './pages/FAQ';
import Passengers from './pages/Passengers';
import Landing from './pages/Landing';
import PaymentSuccess from './pages/PaymentSuccess';
import logo from './assets/logo.png';
import {  HomeOutlined, TeamOutlined, TagsOutlined, PhoneOutlined, QuestionCircleOutlined, DashboardOutlined } from '@ant-design/icons';
import Admin from './pages/Admin';
import Drop from './pages/Drop';
const { Header, Content } = Layout;

export const AuthContext = createContext();
export const NavBarContext = createContext();
export const AuthInfoContext = createContext();

const DEV_ENDPOINT = 'http://localhost:5051';
const PROD_ENDPOINT = 'https://api.steadyshuttle.com';
const BACKEND_ENDPOINT = process.env.NODE_ENV === 'development' ? DEV_ENDPOINT : PROD_ENDPOINT;




function App() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isNavBarVisible, setIsNavBarVisible] = useState(true);
  const [authFailReason, setAuthFailReason] = useState('');
  const [authInfo, setAuthInfo] = useState({ cid: localStorage.getItem('cid'), session_key: localStorage.getItem('session_key') });
  const [menuItems, setMenuItems] = useState([
    { key: '1', label: (<><HomeOutlined /></>), path: '/home' },
    { key: '2', label: (<><TeamOutlined /></>), path: '/passengers' },
    { key: '3', label: (<><TagsOutlined /></>), path: '/bookings' },
    { key: '4', label: (<><QuestionCircleOutlined /></>), path: '/faq' },
    { key: '5', label: (<><PhoneOutlined /></>), path: '/support' },
    (authInfo.cid == "24011163" && { key: '6', label: (<><DashboardOutlined/></>), path: '/admin' }),
  ]);


  // navbar funcs
  const [selectedKey, setSelectedKey] = useState('1');
  const handleMenuSelect = ({ key }) => {
    setSelectedKey(key);
    if (key == '1') {
      navigate('/home');
    } else if (key == '2') {
      navigate('/passengers');
    } else if (key == '3') {
      navigate('/bookings');
    } else if (key == '4') {
      navigate('/faq');
    } else if (key == '5') {
      navigate('/support');
    } else if (key == '6') {
      navigate('/admin');
    }
  };

  // useEffect(() => {
  //   if (authInfo.cid == "24011163") {
  //     setMenuItems([
  //       { key: '1', label: (<><HomeOutlined /></>), path: '/home' },
  //       { key: '2', label: (<><TeamOutlined /></>), path: '/passengers' },
  //       { key: '3', label: (<><TagsOutlined /></>), path: '/bookings' },
  //       { key: '4', label: (<><QuestionCircleOutlined /></>), path: '/faq' },
  //       { key: '5', label: (<><PhoneOutlined /></>), path: '/support' },
  //       { key: '6', label: (<><DashboardOutlined /></>), path: '/admin' },
  //     ])
  //   } else {
  //     setMenuItems([
  //       { key: '1', label: (<><HomeOutlined /></>), path: '/home' },
  //       { key: '2', label: (<><TeamOutlined /></>), path: '/passengers' },
  //       { key: '3', label: (<><TagsOutlined /></>), path: '/bookings' },
  //       { key: '4', label: (<><QuestionCircleOutlined /></>), path: '/faq' },
  //       { key: '5', label: (<><PhoneOutlined /></>), path: '/support' },
  //     ])
  //   }
  // }, [authInfo])
  useEffect(() => {
    // check if the user is authenticated and update the state accordingly
    const auth = true; // replace with your own authentication logic
    setIsAuthenticated(auth);
    // refresh authInfo
    setAuthInfo({ cid: localStorage.getItem('cid'), session_key: localStorage.getItem('session_key') });
  }, []);


  useEffect(() => {
    setMenuItems([
      { key: '1', label: (<><HomeOutlined /></>), path: '/home' },
      { key: '2', label: (<><TeamOutlined /></>), path: '/passengers' },
      { key: '3', label: (<><TagsOutlined /></>), path: '/bookings' },
      { key: '4', label: (<><QuestionCircleOutlined /></>), path: '/faq' },
      { key: '5', label: (<><PhoneOutlined /></>), path: '/support' },
      (authInfo.cid == "24011163" && { key: '6', label: (<><DashboardOutlined/></>), path: '/admin' }),
    ])  
  }, [authInfo])

  const toggleNavBarVisibility = (target) => {
    setIsNavBarVisible(target);
  };

  const verifyAuth = () => {
    // console.log("verifying auth..")
    // make a request to the backend to verify the session
    let cid = localStorage.getItem('cid');
    let session_key = localStorage.getItem('session_key');
    axios.post(`${BACKEND_ENDPOINT}/api/auth`, { cid, session_key })
      .then(response => {
        if (response.data.success) {
          console.log("auth ok")
          setAuthInfo({ cid: cid, session_key: session_key });
        } else {
          // retry
          cid = localStorage.getItem('cid');
          session_key = localStorage.getItem('session_key');
          axios.post(`${BACKEND_ENDPOINT}/api/auth`, { cid, session_key })
            .then(response => {
              if (response.data.success) {
                console.log("auth ok")
                setAuthInfo({ cid: cid, session_key: session_key });
              } else {
                setAuthFailReason(response.data.reason)
                navigate('/invalid-session');
              }
          })
        }
      })
      .catch(error => {
        console.log("error", error)
        setAuthFailReason("Networking error, could not connect to verification server")
        navigate('/invalid-session');
      });
  }

  return (
    <AuthContext.Provider value={{isAuthenticated, setIsAuthenticated, authFailReason, setAuthFailReason, verifyAuth}}>
      <NavBarContext.Provider value={{toggleNavBarVisibility, setSelectedKey, selectedKey}}>
        <AuthInfoContext.Provider value={{ authInfo, setAuthInfo }}>
            <Layout>
              <Header style={{ display: isNavBarVisible ? 'block' : 'none' }}>
                <div className="logo" />
                {/* <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
                  <Menu.Item key="1">Route 1</Menu.Item>
                  <Menu.Item key="2">Route 2</Menu.Item>
                  <Menu.Item key="3">Route 3</Menu.Item>
                </Menu> */}

                <Menu
                  theme="dark"
                  mode="horizontal"
                  items={menuItems}
                  selectedKeys={[selectedKey]}
                  onSelect={handleMenuSelect}
                />
              </Header>
              <Content style={{ padding: 0, minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#f0f2f5' }}>
                <div className="site-layout-content" style={{ background: '#fff', padding: '24px', borderRadius: '8px', width: "90%", overflowX: "scroll", marginTop: "2em", marginBottom: "3em", maxWidth: "100%" }}>
                  {
                    isNavBarVisible &&
                    <>
                    {/* <Alert message="[Update: This issue has been resolved as of 3 July 2024] Due to recent changes in KTMB's booking system, receipt PDFs are not being generated correctly on SteadyShuttle. However, your confirmed bookings remain valid by scanning your passport at the departure gate. We are working on a fix and will update this message once resolved." 
                    closable={false}
                    type="warning" 
                    style={{
                      marginBottom: "2em"
                    }}/> */}
                    </>
                  }
                  <Routes>
                    <Route path="/s/:sess" element={<VerificationComponent />} />
                    <Route exact path="/" element={<Landing />} />
                    <Route path="/invalid-session" element={<InvalidSessionComponent />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/passengers" element={<Passengers />} />
                    <Route path="/bookings" element={<Bookings />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/support" element={<Support />} />
                    <Route path="/admin" element={<Admin/>} />
                    <Route path="/payment-success" element={<PaymentSuccess />} />
                    <Route path='/drop/:dropcode' element={<Drop />} />
                  </Routes>
                </div>
              </Content>
            </Layout>
        </AuthInfoContext.Provider>
      </NavBarContext.Provider>
    </AuthContext.Provider>
  );
}

function VerificationComponent() {
  const navigate = useNavigate();
  const {isAuthenticated, setIsAuthenticated, authFailReason, setAuthFailReason, verifyAuth} = useContext(AuthContext);
  const {toggleNavBarVisibility, setSelectedKey, selectedKey} = useContext(NavBarContext);
  const { authInfo, setAuthInfo } = useContext(AuthInfoContext);
  const { sess } = useParams();
  const [cid, session_key] = sess.split(':');

  useEffect(() => {
    // if ':' does not exist in sess, redirect to invalid session page
    if (cid === undefined || session_key === undefined) {
      setAuthFailReason("Malformed session key")
      navigate('/invalid-session')
    }
    

    
    // make a request to the backend to verify the session
    axios.post(`${BACKEND_ENDPOINT}/api/auth`, { cid, session_key })
      .then(response => {
        if (response.data.success) {
          // save auth info to local storage
          console.log("auth ok")
          localStorage.setItem('cid', cid);
          localStorage.setItem('session_key', session_key);
          setAuthInfo({ cid: cid, session_key: session_key });
          navigate('/home')
          setIsAuthenticated(true);
        } else {
          setAuthFailReason(response.data.reason)
          navigate('/invalid-session');
        }
      })
      .catch(error => {
        console.log("error", error)
        setAuthFailReason("Networking error, could not connect to verification server")
        navigate('/invalid-session');
        
      });

    
  }, [cid, session_key]);

  useEffect(() => {
    // hide navbar in this component
    toggleNavBarVisibility(false);
    return () => {
      toggleNavBarVisibility(true);
    };
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '30em' }}>
      <div style={{ textAlign: 'center' }}>
        <Spin size="large" />
        <h1 style={{ marginTop: '1em' }}>Verifying your credentials</h1>
        <p style={{ marginTop: '0.5em', color: '#999' }}>Please wait a moment...</p>
      </div>
    </div>
  );
}

function InvalidSessionComponent() {
  const navigate = useNavigate();
  const { isAuthenticated, authFailReason, setAuthFailReason } = useContext(AuthContext);
  const {toggleNavBarVisibility, setSelectedKey, selectedKey} = useContext(NavBarContext);
  const { authInfo, setAuthInfo } = useContext(AuthInfoContext);
  useEffect(() => {
    // hide navbar in this component
    toggleNavBarVisibility(false);
    // delete cid and session_key from local storage
    localStorage.removeItem('cid');
    localStorage.removeItem('session_key');
    return () => {
      // show navbar again when component unmounts
      toggleNavBarVisibility(true);
    };
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <div style={{
        textAlign: 'center',
      }}>
        <h1>Unable to verify credentials</h1>
        {
          authFailReason != '' &&
            <p>
              Reason: {authFailReason}
            </p>
        }
        <p><a href='tg://resolve?domain=SteadyShuttleBot'>You can login again here.</a></p>
      </div>
    </div>
  );
}

export default App;