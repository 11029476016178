import React, { useState, useEffect, useContext } from 'react'
import MetaDescription from './MetaDescription'
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, useParams, Link } from 'react-router-dom';
import { AuthContext, NavBarContext } from '../App';
import { LoadingOutlined } from '@ant-design/icons';
import '../Drop.css';
import { Spin } from 'antd';

function Drop(props) {
    const navigate = useNavigate();
    const { isAuthenticated, setIsAuthenticated, authFailReason, setAuthFailReason, verifyAuth } = useContext(AuthContext);
    const { toggleNavBarVisibility, setSelectedKey, selectedKey } = useContext(NavBarContext);
    const { dropcode } = useParams();

    const [dropStates, setDropStates] = useState(0)
    // 0 - verifying auth
    // 1 - verifying dropcode
    // 2 - redeeming dropcode
    // 3 - dropcode redeemed successfully
    // 4 - dropcode redemption failed

    useEffect(() => {
        verifyAuth();
        toggleNavBarVisibility(false);
        return () => {
            toggleNavBarVisibility(true);
        };
    }, []);

    return (
        <div style={{
            fontFamily: 'GeistSans',
        }}>
            <MetaDescription description="Fastest fingers first - Redeem a coupon code from SteadyShuttle Drops." />
            <h1>SteadyShuttle Drops</h1>
        </div>
    )
}

export default Drop