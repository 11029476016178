import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button } from 'antd';
import { AuthContext, NavBarContext } from '../App';
import bannerImage from '../assets/train.jpg';

const { Title, Paragraph } = Typography;

function Landing() {
  const navigate = useNavigate();
  const { toggleNavBarVisibility } = useContext(NavBarContext);
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    toggleNavBarVisibility(false);
    // if authenticated, route to home
    
    if (localStorage.getItem('cid')) {
      navigate('/home');
    }

    return () => {
      toggleNavBarVisibility(true);
    };
  }, [toggleNavBarVisibility]);

  const handleGetStartedClick = () => {
    // Handle the click event when users want to get started
    // Redirect them to the signup or onboarding page
    // navigate('/signup');
    // try tg://resolve?domain=SteadyShuttleBot first, then fallback to https://t.me/steadyshuttlebot
    window.location.href = 'tg://resolve?domain=SteadyShuttleBot';
    window.location.href = 'https://t.me/steadyshuttlebot';
  };

  return (
    <div style={styles.container}>
      <div style={styles.banner}>
        <img src={bannerImage} alt="SteadyShuttle Banner" style={styles.bannerImage} />
        <div style={styles.bannerContent}>
          <Title level={2} style={styles.title}>
            Welcome to SteadyShuttle
          </Title>
          <Paragraph style={styles.subtitle}>
            Hassle-free train ticket booking made easy.
          </Paragraph>
          {/* <Button type="primary" size="large" onClick={handleGetStartedClick}>
            Get Started
          </Button> */}
        </div>
      </div>

      <section style={styles.section}>
        <div style={styles.sectionContent}>
          <Title level={3}>What is SteadyShuttle?</Title>
          <Paragraph>
            SteadyShuttle is a hassle-free platform that allows you to book train tickets between Woodlands CIQ and JB Sentral with ease. Say goodbye to long queues and sold-out dates. With SteadyShuttle, you can secure your tickets hassle-free.
          </Paragraph>
        </div>
      </section>

      <section style={styles.section}>
        <div style={styles.sectionContent}>
          <Title level={3}>How does it work?</Title>
          <Paragraph>
          At SteadyShuttle, we understand that tickets for high-demand timeslots can often be sold out. That's why our system works tirelessly to secure tickets for you, even when they appear to be unavailable. Our system continually checks for tickets that become available when existing passengers cancel their bookings. This allows us to find and secure tickets for you, ensuring you can travel by train even when tickets are initially sold out.
          </Paragraph>
        </div>
      </section>

      <section style={styles.section}>
        <div style={styles.sectionContent}>
          <Title level={3}>Easy-to-Use Web App</Title>
          <Paragraph>
            Our web app (accessible by our Telegram bot) allows you to book tickets with ease. With a user-friendly interface and intuitive design, you can quickly find available trains, select your preferred timeslots, and secure your booking in just a few clicks. We'll even notify you via Telegram when your booking has updates.
          </Paragraph>
        </div>
      </section>

      <section style={styles.section}>
        <div style={styles.sectionContent}>
          <Title level={3}>24/7 Support</Title>
          <Paragraph>
            We are dedicated to providing excellent customer support. Our team is available round the clock to assist you with any questions or concerns you may have. Reach out to us via Telegram chat.
          </Paragraph>
        </div>
      </section>

      <footer style={styles.footer}>
        <Paragraph style={styles.footerText}>
          Ready to experience stress-free train ticket booking?
        </Paragraph>
        <Button type="primary" size="large" onClick={handleGetStartedClick}>
          Open in Telegram
        </Button>
      </footer>
    </div>
  );
}

export default Landing;

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '40px',
  },
  banner: {
    position: 'relative',
    marginBottom: '40px',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  bannerImage: {
    width: '100%',
    height: 'auto',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  
  bannerContent: {
    position: 'absolute',
    bottom: '0',
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    color: '#fff',
    padding: '20px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    height: '100%',
    width: '100%',
    maxWidth: '800px', // Add this property to limit the content width
    margin: '0 auto', // Center the content horizontally
  },

  title: {
    marginBottom: '10px',
    fontSize: '2em',
    color: '#fff',
    textShadow: '0px 0px 5px #000',
  },
  subtitle: {
    marginBottom: '40px',
    color: '#fff',
  },
  section: {
    marginBottom: '80px',
  },
  sectionContent: {
    textAlign: 'center',
  },
  footer: {
    textAlign: 'center',
    borderTop: '1px solid #ddd',
    paddingTop: '20px',
  },
  footerText: {
    marginBottom: '0.5em',
  },
};
