import React, { useEffect, createContext, useContext, useState, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Layout, Menu, Spin, Typography, List, Modal, Form, Input, Button, DatePicker, Radio, Divider, Table, Checkbox, Select, Space, Popconfirm, Tag, Tooltip, Popover, Dropdown, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { AuthContext, NavBarContext, AuthInfoContext } from '../App';
import { UserOutlined, UserAddOutlined, UserDeleteOutlined, LoadingOutlined,LinkOutlined, SearchOutlined, UnorderedListOutlined, DownOutlined, BranchesOutlined, PlayCircleOutlined, PauseCircleOutlined, SendOutlined, CheckCircleOutlined, DownloadOutlined, ExclamationCircleOutlined, FileAddOutlined, QuestionCircleOutlined, SyncOutlined, ClockCircleOutlined, CloseCircleOutlined, EyeOutlined, FileTextOutlined, FormOutlined, ArrowLeftOutlined, ArrowRightOutlined, DollarCircleOutlined, FileExcelOutlined } from '@ant-design/icons';
const DEV_ENDPOINT = 'http://localhost:5051';
const PROD_ENDPOINT = 'https://api.steadyshuttle.com';
const BACKEND_ENDPOINT = process.env.NODE_ENV === 'development' ? DEV_ENDPOINT : PROD_ENDPOINT;
const { Title, Text, Paragraph } = Typography;
const { Option } = Select;

function lightenColor(hexCode, amount) {
  // Remove the '#' symbol and convert hex code to RGB values
  hexCode = hexCode.replace(/^#/, '');
  const r = parseInt(hexCode.substr(0, 2), 16);
  const g = parseInt(hexCode.substr(2, 2), 16);
  const b = parseInt(hexCode.substr(4, 2), 16);

  // Adjust the RGB values to make the color lighter
  const newR = Math.min(255, r + amount);
  const newG = Math.min(255, g + amount);
  const newB = Math.min(255, b + amount);

  // Convert the adjusted RGB values back to a hex code
  const newHexCode = `#${newR.toString(16).padStart(2, '0')}${newG.toString(16).padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;
  return newHexCode;
}



const trainsTowardsJohor = [
  { label: "8.30AM (Train #72)", value: 72 },
  { label: "9.45AM (Train #74)", value: 74 },
  { label: "11.00AM (Train #76)", value: 76 },
  { label: "12.30PM (Train #78)", value: 78 },
  { label: "1.45PM (Train #80)", value: 80 },
  { label: "3.00PM (Train #82)", value: 82 },
  { label: "4.15PM (Train #84)", value: 84 },
  { label: "5.30PM (Train #86)", value: 86 },
  { label: "6.45PM (Train #88)", value: 88 },
  { label: "8.00PM (Train #90)", value: 90 },
  { label: "9.15PM (Train #92)", value: 92 },
  { label: "10.30PM (Train #94)", value: 94 },
  { label: "11.45PM (Train #96)", value: 96 }
];

const trainsTowardsSingapore = [
  { label: "5.00AM (Train #61)", value: 61 },
  { label: "5.30AM (Train #63)", value: 63 },
  { label: "6.00AM (Train #65)", value: 65 },
  { label: "6.30AM (Train #67)", value: 67 },
  { label: "7.00AM (Train #69)", value: 69 },
  { label: "7.30AM (Train #71)", value: 71 },
  { label: "8.45AM (Train #73)", value: 73 },
  { label: "10.00AM (Train #75)", value: 75 },
  { label: "11.30AM (Train #77)", value: 77 },
  { label: "12.45PM (Train #79)", value: 79 },
  { label: "2.00PM (Train #81)", value: 81 },
  { label: "3.15PM (Train #83)", value: 83 },
  { label: "4.30PM (Train #85)", value: 85 },
  { label: "5.45PM (Train #87)", value: 87 },
  { label: "7.00PM (Train #89)", value: 89 },
  { label: "8.15PM (Train #91)", value: 91 },
  { label: "9.30PM (Train #93)", value: 93 },
  { label: "10.45PM (Train #95)", value: 95 }
];



const handleButtonClick = (e) => {
  message.info('Click on left button.');
  console.log('click left button', e);
};

function Admin() {
  const navigate = useNavigate();
  const { isAuthenticated, setIsAuthenticated, authFailReason, setAuthFailReason, verifyAuth } = useContext(AuthContext);
  const { toggleNavBarVisibility, setSelectedKey, selectedKey } = useContext(NavBarContext);
  const { authInfo, setAuthInfo } = useContext(AuthInfoContext);

  const [bookings, setBookings] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [selectedDirection, setSelectedDirection] = useState(0);
  const [selectedTrains, setSelectedTrains] = useState([]);
  const [passengers, setPassengers] = useState([]);
  const [formSubmissionLoading, setFormSubmissionLoading] = useState(false);
  const [cidTagColours, setCidTagColours] = useState({});
  const [otherSelected, setOtherSelected] = useState(false);
  const [cost, setCost] = useState(0);
  const [search, setSearch] = useState('');
  const [showModifyDateModal, setShowModifyDateModal] = useState(false);
  const [modifyDateBooking, setModifyDateBooking] = useState('');
  const [modifyDateValue, setModifyDateValue] = useState('');
  const [hasFetchedAll, setHasFetchedAll] = useState(false);

  const [totalMYR, setTotalMYR] = useState(0);
  const [totalSGD, setTotalSGD] = useState(0);


  const handleTrainSelect = (value) => {
    setSelectedTrains(value);
  }



  const formRef = useRef(null);

  const fetchPassengers = () => {
    axios.get(BACKEND_ENDPOINT + '/api/all_passengers', {
      headers: {
        cid: authInfo.cid,
        sess: authInfo.session_key,
      },
    })
      .then((response) => {
        // console.log(response)
        const data = response.data.passengers.map((passenger) => ({
          ...passenger,
          key: passenger._id,
        }));
        // console.log(data)
        setPassengers(response.data.passengers);
      })
      .catch((error) => {
        console.error('Error fetching passengers:', error);
      });
  };

  const fetchBookings = () => {
    axios.get(BACKEND_ENDPOINT + '/api/all_bookings', {
      headers: {
        cid: authInfo.cid,
        sess: authInfo.session_key,
        status: 'working'
      },
    })
      .then((response) => {
        const data = response.data.bookings.map((booking) => ({
          ...booking,
          key: booking._id,
        }));
        // console.log(response.data.bookings)
        setBookings(data);
        setTableData(data);

        let myrCount = 0;
        // loop through all bookings
        for (let i = 0; i < data.length; i++) {
          // get booking direction
          const direction = parseInt(data[i].targetDirection);
          if (direction == 0) {
            myrCount += 16.95
          } else {
            myrCount += 5
          }
        }
        setTotalMYR(myrCount);
        setTotalSGD(myrCount / 3.2);

        const generateColor = (text) => {
          let color = cidTagColours[text];
          if (!color) {
            color = "#" + Math.floor(Math.random()*16777215).toString(16);
            // Modify color by changing the numbers to make it whiter
            lightenColor(color, 200);
            // const cidTagColoursCopy = {...cidTagColours};
            // cidTagColoursCopy[text] = color;
            // Update state by making a copy of the object
            // console.log(cidTagColoursCopy)
            // setCidTagColours(cidTagColoursCopy);
          }
          return color;
        };
        
          // Call the generateColor function for each unique cid in your data
          // Assuming you have access to the data used in the table
          
          const uniqueCids = [...new Set(response.data.bookings.map((booking) => booking.cid))];

          let newTagColors = []
          uniqueCids.forEach((cid) => {
            // console.log("generate colour for " + cid)
            let color = generateColor(cid);
            // console.log(color)
            // setCidTagColours(cidTagColoursCopy);
            newTagColors.push({cid: cid, color: color})
          });
          let tempTagColours = { ...cidTagColours };
          newTagColors.forEach((tag) => {
            tempTagColours[tag.cid] = tag.color;
          });
          setCidTagColours(tempTagColours);
          })
      .catch((error) => {
        console.error('Error fetching bookings:', error);
      });
  };

  const handleFetchAll = () => {
    setHasFetchedAll(true);
    axios.get(BACKEND_ENDPOINT + '/api/all_bookings', {
      headers: {
        cid: authInfo.cid,
        sess: authInfo.session_key,
      },
    })
      .then((response) => {
        const data = response.data.bookings.map((booking) => ({
          ...booking,
          key: booking._id,
        }));
        // console.log(response.data.bookings)
        setBookings(data);
        setTableData(data);
        message.success('Fetch Completed')
        if (search != '') {
          processSearch();
        }
    const generateColor = (text) => {
      let color = cidTagColours[text];
      if (!color) {
        color = "#" + Math.floor(Math.random()*16777215).toString(16);
        // Modify color by changing the numbers to make it whiter
        lightenColor(color, 200);
        const cidTagColoursCopy = {...cidTagColours};
        cidTagColoursCopy[text] = color;
        // Update state by making a copy of the object
        setCidTagColours(cidTagColoursCopy);
      }
      return color;
    };
    
      // Call the generateColor function for each unique cid in your data
      // Assuming you have access to the data used in the table
      const uniqueCids = [...new Set(data.map((item) => item.cid))];
      uniqueCids.forEach((cid) => {
        generateColor(cid);
      });
      })
      .catch((error) => {
        console.error('Error fetching bookings:', error);
      });
  };

  const handleAddBooking = async (bookingDetails) => {
    setFormSubmissionLoading(true);
    try {
      const formattedValues = {
        date: bookingDetails.date,
        direction: bookingDetails.direction,
        passenger: bookingDetails.passenger,
        train: bookingDetails.train,
      };
      const response = await axios.post(BACKEND_ENDPOINT + '/api/bookings', formattedValues, {
        headers: {
          cid: authInfo.cid,
          sess: authInfo.session_key,
        },
      });
      formRef.current.resetFields();
      fetchBookings();
      setIsModalVisible(false);
      setFormSubmissionLoading(false);
    } catch (error) {
      console.error('Error adding booking:', error);
      console.log(error.response.data.error);
      setFormSubmissionLoading(false);
    }
  };


  const processSearch = () => {
    let searchText = search;
    const filteredData = bookings.filter((booking) => {
      // search by cid or booking id
      if (booking.cid.toLowerCase().includes(searchText.toLowerCase()) || booking._id.toLowerCase().includes(searchText.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    })

    setTableData(filteredData);
    message.success('Search Completed')
  }

  useEffect(() => {
    verifyAuth();
    setSelectedKey('6');
    toggleNavBarVisibility(true);
    fetchBookings();
    return () => {
      toggleNavBarVisibility(true);
    };
  }, []);



  const columns = [
    {
      title: 'State',
      dataIndex: 'status',
      key: 'status',
      // defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        switch (a.status) {
          case 'working':
            return -1;
          case 'reviewing':
            return b.status === 'working' ? 1 : -1;
          case 'payment_pending':
            return b.status === 'working' || b.status === 'reviewing' ? 1 : -1;
          case 'booked':
            return b.status === 'user_cancelled' || b.status === 'admin_cancelled' || b.status === 'refunded' || b.status === 'cancelled' || b.status === 'rejected' ? -1 : 1;
          case 'user_cancelled':
            return b.status === 'admin_cancelled' || b.status === 'refunded' || b.status === 'cancelled' || b.status === 'rejected' ? -1 : 1;
          case 'admin_cancelled':
            return b.status === 'refunded' || b.status === 'cancelled' || b.status === 'rejected' ? -1 : 1;
          case 'refunded':
            return b.status === 'cancelled' || b.status === 'rejected' ? -1 : 1;
          case 'cancelled':
            return b.status === 'rejected' ? -1 : 1;
          case 'rejected':
            return 1;
          default:
            return 0;
        }
      },
      filters: [
        {
          text: 'Working',
          value: 'working',
        },
        {
          text: 'Booked',
          value: 'booked',
        },
        {
          text: 'Cancelled',
          // all cancelled statuses
          value: ['user_cancelled', 'admin_cancelled', 'refunded', 'cancelled', 'rejected'],
        },
        {
          text: 'Reviewing',
          value: 'reviewing',
        }
      ],
      render: (text, record) => {
        if (text === "working") {
          return <Tooltip title={("Booking ID: " + record._id)}>
            <Tag color={(record.cid == "24011163" ? "success" : "processing")} icon={<SyncOutlined spin />}/>
          </Tooltip>;
        } else if (text === "reviewing") {
          return <Tooltip title={("Booking ID: " + record._id)}>
            <Tag icon={<ClockCircleOutlined />} color="default">Pending Review</Tag>
          </Tooltip>;
        } else if (text === "paused") {
          return <Tag icon={<PauseCircleOutlined />} color="warning">Paused</Tag>;
        } else if (text === "payment_pending") {
          return <Tag icon={<ClockCircleOutlined />} color="gold">Pending Payment</Tag>;
        } else if (text === "queued") {
          return <Tooltip title={"We've put this booking into the queue. Queueing bookings links them together and helps prevent your passengers from being booked onto different train timeslots."}><Tag icon={<SyncOutlined />} color="gold"></Tag></Tooltip>;
        } else if (text === "booked") {
          return <Tag icon={<CheckCircleOutlined />} color="success">Booked</Tag>;
        } else if (text === "user_cancelled") {
          return <Tag icon={<CloseCircleOutlined />} color="error">Cancelled By You</Tag>;
        } else if (text === "admin_cancelled") {
          return <Tag icon={<CloseCircleOutlined />} color="error">Cancelled By Admin</Tag>;
        } else if (text === "refunded") {
          return <Tag icon={<CloseCircleOutlined />} color="error">Refunded</Tag>;
        } else if (text === "cancelled") {
          return <Tag icon={<CloseCircleOutlined />} color="error">Cancelled</Tag>;
        } else if (text === "rejected") {
          return (<Link to="/support"><Tag icon={<ExclamationCircleOutlined />} color="error">Rejected - Contact Support</Tag>
          </Link>);
        }
      }
    },
    {
      title: 'Date',
      dataIndex: 'targetDate',
      key: 'targetDate',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => moment(a.targetDateFull) - moment(b.targetDateFull),
      render: (text, record) => (
        <Space>
          <Text>{moment(record.targetDateFull).format('D MMM (ddd)')}</Text>
        </Space>
      )
    },
    {
      title: 'Book Time',
      dataIndex: 'requestTime',
      // defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.targetDateObj).unix() - moment(b.targetDateObj).unix(),
      key: 'requestTime',
      render: (text) => {
        // accept text as unix timestamp, render as moment obj
        return <Space>
          <Text>{moment.unix(text).format('D MMM HH:mm')}</Text>
        </Space>
      }
    },
    {
      title: 'Remain',
      render: (text, record) => {
          let nowTime = moment()
          // create target time based on the earliest train timing in that booking
          // get train timing based on direction and train number
          if (record.targetDirection == "0") { // towards jb
            // parse train timing from train number in trainsTowardsJohor
            // where { label: "8.30AM (Train #72)", value: 72 }
            // get 8.30AM from label
            try {
              let trainTiming = trainsTowardsJohor.find(train => train.value == record.targetTrain[0]).label.split(" ")[0];
              // create target time based on train timing and target date
              var targetTime = moment(record.targetDateFull + " " + trainTiming, "YYYY-MM-DD HH:mmA");
            } catch {
              return <Text type="danger">Error</Text>
            }
          } else { // towards woodlands
            // parse train timing from train number in trainsTowardsWoodlands
            // where { label: "8.30AM (Train #72)", value: 72 }
            // get 8.30AM from label
            try {
              let trainTiming = trainsTowardsSingapore.find(train => train.value == record.targetTrain[0]).label.split(" ")[0];
              // create target time based on train timing and target date
              var targetTime = moment(record.targetDateFull + " " + trainTiming, "YYYY-MM-DD HH:mmA");
            } catch {
              return <Text type="danger">Error</Text>
            }
          }

          let diff = targetTime.diff(nowTime, 'minutes');
          if (diff < 0) {
            return <Text type="danger">Departed</Text>
          } else {
            // return in days, hours or mins depending on diff
            if (diff >= 1440) {
              const days = Math.floor(diff / 1440);
              const hours = Math.floor((diff % 1440) / 60);
              return <Text>{days}d {hours}h</Text>;
            }
            else if (diff >= 60) {
              const hours = Math.floor(diff / 60);
              const minutes = diff % 60;
              return <Text>{hours}h {minutes}m</Text>;
            }
            else {
              return <Text>{diff}m</Text>;
            }
            
          }
      }
    },
    {
      title: (<>
        <Tooltip title="Hover over the train number tags to view the actual timing.">
          Train
        </Tooltip>
      </>),
      dataIndex: 'targetTrain',
      key: 'targetTrain',
      render: (text, record) => (

        <Space>
          {text.map((train) => (
            <Tooltip key={record._id + train} title={(
              <span>{[
                ...trainsTowardsJohor,
                ...trainsTowardsSingapore,
              ].find(t => t.value === train).label}</span>
            )}>
              <p>{train}</p>
            </Tooltip>
          ))}
        </Space>
      )
    },
    {
      title: 'Dir',
      dataIndex: 'targetDirection',
      key: 'targetDirection',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.targetDirection - b.targetDirection,
      render: (text) => (text === 1 ? (<Tooltip title="Towards Singapore"><Tag color="orange">1</Tag></Tooltip>) : (<Tooltip title="Towards Johor"><Tag color="green">0</Tag></Tooltip>)), // assuming 0 for inbound and 1 for outbound
    },
    {
      title:  'Action',
      key: '_id',
      render: (text, record) => 
          {

            const handleMenuClick = (e) => {
              // split record id and action id
              let recordId = e.key.split("_")[0];
              let actionId = e.key.split("_")[1];


              if (actionId === "6") {
                setModifyDateBooking(recordId)
                setShowModifyDateModal(true);
                return
              } else {
                message.info('Processing action..');
              }
              // post to /api/bookings/action/<booking_id> with action_code = actionId as a json parameter

              axios.post(BACKEND_ENDPOINT + `/api/bookings/action/${recordId}`, {
                action_code: actionId,
              }, {
                headers: {
                  cid: authInfo.cid,
                  sess: authInfo.session_key,
                }
              })
              .then(function (response) {
                console.log(response);
                message.success('Done!');
              }
              )
              .catch(function (error) {
                console.log(error);
                message.error('Error processing action: ' + error.response.data.error);
              }
              );
            };
            
            const items = [
              {
                label: 'Pause',
                key: record._id + '_3',
                // record: record,
                icon: <PauseCircleOutlined />,
                // danger: true,
              },
              {
                label: 'Resume',
                key: record._id + '_4',
                // record: record,
                icon: <PlayCircleOutlined />,
                // danger: true,
              },
              {
                label: 'Chain Job',
                key: record._id + '_5',
                icon: <BranchesOutlined />
              },
              {
                label: 'Patch Date',
                key: record._id + '_6',
                icon: <FormOutlined />
              },
              {
                label: 'Notify Refund',
                key: record._id + '_2',
                // record: record,
                icon: <SendOutlined/>,
                danger: true,
              },
              {
                label: 'Cancel',
                key: record._id + '_1',
                // record: record,
                icon: <CloseCircleOutlined />,
                danger: true,
              },
              {
                label: 'Approve',
                key: record._id + '_100',
                // record: record,
                // icon: <CloseCircleOutlined />,
                // danger: true,
              },
              {
                label: 'Reject',
                key: record._id + '_200',
                // record: record,
                // icon: <CloseCircleOutlined />,
                // danger: true,
              },
            ];
            const menuProps = {
              items,
              onClick: handleMenuClick,
            };
            return (
          <Space size="middle">
            <Dropdown menu={menuProps}>
              <Button>
                <Space>
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Space>
        )
            }
    },
    {
      title: 'CID',
      dataIndex: 'cid',
      key: '_id',
      render : (text, record) => {
        let color = cidTagColours[text];
        return <Tag color={color}>{text}</Tag>
      }
    },
    {
      title: 'Passenger',
      dataIndex: ['passenger', 'name'],
      key: ['passenger', 'name'],
      sorter: (a, b) => a.passenger.name.localeCompare(b.passenger.name),
      render: (text, record) => (
        <Space>
          <Tooltip title={(
            <div>
              <Text style={{ color: "#fff" }}>Name: {record.passenger.name}</Text>
              <br />
              <Text style={{ color: "#fff" }}>Passport: {record.passenger.passport}</Text>
              <br />
              <Text style={{ color: "#fff" }}>Contact: {record.passenger.contact}</Text>
            </div>
          )}>

            <Text>{record.passenger.name.slice(0, 7)}{record.passenger.name.length > 7 && "..."}</Text>
          </Tooltip>
        </Space>
      )
    },
    {
      title: <><Tooltip title="Runner Last Refreshed">RLR</Tooltip></>,
      dataIndex: 'runnerLastRefreshed',
      key: 'runnerLastRefreshed',
      render: (text, record) => {
        if (record.runnerLastRefreshed) {
          if (moment(record.runnerLastRefreshed).isBefore(moment().subtract(30, 'minutes'))) { // change this to be (more than 30 mins ago)
            return <Text style={{ fontSize: "0.7em", fontWeight: "bolder", color: "red" }}>{moment(record.runnerLastRefreshed).fromNow().replace('minute', 'min').replace('seconds', 'sec')}</Text>
          } else {
            return <Text style={{ fontSize: "0.7em"}}>{moment(record.runnerLastRefreshed).fromNow().replace('minute', 'min').replace('seconds', 'sec')}</Text>
          }
        } else {
          return <Text style={{ fontWeight: "bolder", color: "red" }}>N/A</Text>
        }
      }
    },
    {
      title: <><Tooltip title="Last Load Time">LLT</Tooltip></>,
      dataIndex: 'runnerLastLoadTime',
      key: 'runnerLastLoadTime',
      render: (text, record) => {
        if (record.runnerLastLoadTime && record.runnerLastLoadTime > 0) {
          return <Text style={{ fontSize: "0.7em"}}>{record.runnerLastLoadTime}ms</Text>
        } else {
          return <Text>N/A</Text>
        }
      }
    },
    {
      title: <><Tooltip title="Payment Intent">PI</Tooltip></>,
      dataIndex: 'payment_intent',
      key: 'payment_intent',
      render: (text, record) => {
        if (record.payment_intent) {
          return <Button type="primary" shape="circle" icon={<LinkOutlined />} onClick={() => {
            window.open("https://dashboard.stripe.com/payments/" + record.payment_intent, "_blank")
          }}></Button>
        } else {
          return <Text style={{ fontSize: "0.7em"}}>-</Text>
        }
      }
    },
    {
      title: <><Tooltip title="Self Cancellable">SC</Tooltip></>,
      dataIndex: 'self_cancellable',
      key: 'self_cancellable',
      render: (text, record) => {
        if (record.self_cancellable) {
          return <Text style={{ fontSize: "0.7em", color: 'green'}}>Y</Text>
        } else {
          return <Text style={{ fontSize: "0.7em", color: 'red'}}>N</Text>
        }
      }
    }
  ];


  const handlePatchDate = (e) => {
    // split record id and action id
    
    let recordId = modifyDateBooking;
    let actionId = "6";
    let targetDate = modifyDateValue;
    axios.post(BACKEND_ENDPOINT + `/api/bookings/action/${recordId}`, {
      action_code: actionId,
      target_date: targetDate,
    }, {
      headers: {
        cid: authInfo.cid,
        sess: authInfo.session_key,
      }
    })
    .then(function (response) {
      console.log(response);
      message.success('Done!');
      if (hasFetchedAll) {
        handleFetchAll();
      } else {
        fetchBookings();
      }
    }
    )
    .catch(function (error) {
      console.log(error);
      message.error('Error processing action: ' + error.response.data.error);
    }
    );
  };

  const handleDelete = (id) => {
    // filter passenger from passengers based on matching id
    setSelectedBooking(bookings.filter((booking) => booking._id === id)[0])
    setIsDeleteModalVisible(true)
  };

  const handleDeleteConfirm = () => {
    console.log(`Deleting passenger ${selectedBooking.name} (${selectedBooking.passport})`);

    axios.delete(BACKEND_ENDPOINT + '/api/bookings/' + selectedBooking._id, {
      headers: {
        cid: authInfo.cid,
        sess: authInfo.session_key,
      },
    })
      .then((response) => {
        // console.log(response)
        fetchBookings();
      })
      .catch((error) => {
        console.error('Error deleting booking:', error);
      });

    setIsDeleteModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };

  const handlePayment = (id) => {
    // get booking object from bookings based on matching id
    let payment_booking = bookings.filter((booking) => booking._id === id)[0]
    // open payment_booking.payment_url in new tab
    window.open(payment_booking.payment_url, "_blank")
  };



  const getWeekendDates = () => {
    const today = moment();
    const nextFriday = moment().day(5 + (today.day() > 5 ? 7 : 0));
    const nextSaturday = nextFriday.clone().add(1, 'day');
    const nextSunday = nextFriday.clone().add(2, 'day');
    const nextNextFriday = nextFriday.clone().add(7, 'day');
    const nextNextSaturday = nextSaturday.clone().add(7, 'day');
    const nextNextSunday = nextSunday.clone().add(7, 'day');
    return [nextFriday, nextSaturday, nextSunday, nextNextFriday, nextNextSaturday, nextNextSunday];
  };

  const trainsTowardsJohor = [
    { label: "8.30AM (Train #72)", value: 72 },
    { label: "9.45AM (Train #74)", value: 74 },
    { label: "11.00AM (Train #76)", value: 76 },
    { label: "12.30PM (Train #78)", value: 78 },
    { label: "1.45PM (Train #80)", value: 80 },
    { label: "3.00PM (Train #82)", value: 82 },
    { label: "4.15PM (Train #84)", value: 84 },
    { label: "5.30PM (Train #86)", value: 86 },
    { label: "6.45PM (Train #88)", value: 88 },
    { label: "8.00PM (Train #90)", value: 90 },
    { label: "9.15PM (Train #92)", value: 92 },
    { label: "10.30PM (Train #94)", value: 94 },
    { label: "11.45PM (Train #96)", value: 96 }
  ];

  const trainsTowardsSingapore = [
    { label: "5.00AM (Train #61)", value: 61 },
    { label: "5.30AM (Train #63)", value: 63 },
    { label: "6.00AM (Train #65)", value: 65 },
    { label: "6.30AM (Train #67)", value: 67 },
    { label: "7.00AM (Train #69)", value: 69 },
    { label: "7.30AM (Train #71)", value: 71 },
    { label: "8.45AM (Train #73)", value: 73 },
    { label: "10.00AM (Train #75)", value: 75 },
    { label: "11.30AM (Train #77)", value: 77 },
    { label: "12.45PM (Train #79)", value: 79 },
    { label: "2.00PM (Train #81)", value: 81 },
    { label: "3.15PM (Train #83)", value: 83 },
    { label: "4.30PM (Train #85)", value: 85 },
    { label: "5.45PM (Train #87)", value: 87 },
    { label: "7.00PM (Train #89)", value: 89 },
    { label: "8.15PM (Train #91)", value: 91 },
    { label: "9.30PM (Train #93)", value: 93 },
    { label: "10.45PM (Train #95)", value: 95 }
  ];



  return (
    <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left', height: '100%', }}>
      <div style={{ textAlign: 'left', width: '100%' }}>
        <Title level={5} style={{ marginTop: '0em' }}><FileTextOutlined /> Booking Admin Dashboard</Title>
        <Space style={{ marginBottom: 8, marginRight: 8 }}>
          {/* <Button type="primary" onClick={() => setIsModalVisible(true)}>
            <FileAddOutlined /> Add Booking
          </Button> */}
        <Button onClick={() => {
          handleFetchAll()
        }} ><DownloadOutlined />Fetch All</Button>
        </Space>
        <Space style={{ marginBottom: 8, marginRight: 8 }}>
        <p>
        Cash for bookings - <Tag color='cyan'>RM{totalMYR.toFixed(2)}</Tag><Tag color='blue'>S${totalSGD.toFixed(2)}</Tag>
        </p>
        </Space>
        <Space style={{ marginBottom: 16 }}>
          <Input placeholder="Search" onChange={(e) => {
            if (e.target.value == '') {
              setSearch('')
              fetchBookings()
            } else {
              setSearch(e.target.value)
            }
          }} />
          <Button onClick={() => {
            processSearch()
          }} ><SearchOutlined /></Button>
        </Space>
        <Table columns={columns} responsive={"scroll"} dataSource={tableData} rowKey="_id" />
        <Modal
          title={<><FileAddOutlined /> Add Booking</>}
          // visible={isModalVisible}
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <Form
            layout="vertical"
            ref={formRef}
            onFinish={handleAddBooking}
          >

            <Form.Item
              name="direction"
              label="Direction"
              rules={[
                {
                  required: true,
                  message: 'Please choose a direction',
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => {
                  formRef.current.setFieldsValue({ train: [] });
                  setSelectedDirection(e.target.value);
                  setCost(e.target.value === 0 ? 9.4 : 8.8);
                }}
              >
                <Radio value={0}>Towards Johor ($9.40)</Radio>
                <Radio value={1}>Towards Singapore ($8.80)</Radio>
              </Radio.Group>
            </Form.Item>


            <Form.Item
              name="passenger"
              label="Passenger"
              rules={[
                {
                  required: true,
                  message: 'Please choose a passenger',
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                optionFilterProp="label"
                loading={!authInfo}
                onChange={(value) => {
                  formRef.current.setFieldsValue({ passenger: value });
                }}
                onFocus={fetchPassengers}
              >
                {passengers.map((passenger) => (
                  <Option key={passenger._id} value={passenger._id} label={passenger.name}>
                    {passenger.name} ({passenger.passport})
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="date"
              label="Date"
              rules={[
                {
                  required: true,
                  message: 'Please choose a date',
                },
              ]}
            >
              <Select
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    {/* <Divider style={{ margin: '4px 0' }} />
                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                    <DatePicker
                      style={{ flex: 1 }}
                      format="ddd, DD MMM YYYY"
                      disabledDate={(current) => current && current < moment().endOf('day')}
                    />
                  </div> */}
                  </div>
                )}
                onChange={(value) => {
                  if (value == "0000-00-00") {
                    setOtherSelected(true)
                  } else {
                    setOtherSelected(false)
                  }
                }}
              >
                <Option key="other" value="0000-00-00">
                  All Other Dates (Mon-Thu) -{" "}
                  <Tooltip title="This option incurs an additional cost because it requires manual processing.">
                    +50¢ <QuestionCircleOutlined />
                  </Tooltip>
                </Option>
                {getWeekendDates().map((date) => (
                  <Option key={date.format('YYYY-MM-DD')} value={date.format('YYYY-MM-DD')}>
                    {date.format('ddd, DD MMM YYYY')}
                  </Option>
                ))}
              </Select>
            </Form.Item>


            <Form.Item
              name="train"
              label="Train"
              rules={[{ required: true, message: 'Please choose a train' }]}
              style={{
                marginBottom: "0"
              }}
            >
              <Select
                mode="multiple"
                placeholder="Please select train(s)"
                onChange={handleTrainSelect}
              >
                {selectedDirection == 0
                  ? trainsTowardsJohor.map((train) => (
                    <Select.Option key={train.value} value={train.value}>
                      {train.label}
                    </Select.Option>
                  ))
                  : trainsTowardsSingapore.map((train) => (
                    <Select.Option key={train.value} value={train.value}>
                      {train.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Text style={{
              fontSize: "0.8em",
              color: "#969696",
              marginTop: "-1em",
            }}>Choose several train slots to increase the likelihood of getting a ticket quickly. We'll book the first available train among those selected.</Text>
            <Divider />

            <Form.Item>
              <Text strong>Total Cost: S${(cost + (otherSelected ? 0.5 : 0)).toFixed(2)}</Text>
              {
                otherSelected &&
                <Paragraph>
                  <Text style={{
                    color: 'red',
                  }}>Please contact support after your booking request is made to let us know what date you'd like to book for. </Text>
                </Paragraph>
              }
              <Paragraph>
                <Text style={{
                  fontSize: "0.8em",
                  color: "#969696"
                }}>Next Steps: Your booking request will be reviewed by our team, and you'll be notified to make payment after it has been approved.</Text>
              </Paragraph>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={formSubmissionLoading} style={{ marginRight: 8 }}>
                {
                  formSubmissionLoading ?
                    <>
                      <SyncOutlined spin /> Loading
                    </>
                    :
                    <>
                      <CheckCircleOutlined /> Confirm Booking Details
                    </>
                }
              </Button>
              <Button onClick={() => setIsModalVisible(false)} style={{ marginRight: 8 }}>
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title={<><FileExcelOutlined /> Confirm Cancellation</>}
          // visible={isDeleteModalVisible}
          open={isDeleteModalVisible}
          onCancel={handleDeleteCancel}
          footer={[
            <Button key="delete" type="primary" danger onClick={handleDeleteConfirm}>
              Confirm Cancellation
            </Button>,
            <Button key="cancel" onClick={handleDeleteCancel}>
              Go Back
            </Button>,
          ]}
        >
          <Paragraph>
            Are you sure you want to cancel booking for {selectedBooking?.passenger.name} ({selectedBooking?.passenger.passport}) on {selectedBooking?.targetDate} (Train {selectedBooking?.targetTrain})?
          </Paragraph>

          <Paragraph>
            <ExclamationCircleOutlined /> This action cannot be reversed.
          </Paragraph>
        </Modal>

        <Modal
          title={<>Modify Date</>}
          open={showModifyDateModal}
          onCancel={ () => { setShowModifyDateModal(false)} }
          onOk={() => {
            setShowModifyDateModal(false)
            message.info("Patching date...")
            handlePatchDate()
            // alert(modifyDateValue)
          }}
          >
            <Paragraph>
              Modifying for {modifyDateBooking}
            </Paragraph>
            <Input
              placeholder="YYYY-MM-DD"
              onChange={(e) => {
                setModifyDateValue(e.target.value)
              }}
            >

            </Input>
        </Modal>
      </div>
    </div>
  );
};

export default Admin;