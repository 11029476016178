import React, { useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Layout, Menu, Spin, Button, Typography, Result } from 'antd';
import axios from 'axios';
import { AuthContext, NavBarContext, AuthInfoContext } from '../App';

const { Title, Text, Paragraph } = Typography;

function PaymentSuccess() {
  const navigate = useNavigate();
  const {
    isAuthenticated,
    setIsAuthenticated,
    authFailReason,
    setAuthFailReason,
    verifyAuth,
  } = useContext(AuthContext);
  const { toggleNavBarVisibility, setSelectedKey, selectedKey } =
    useContext(NavBarContext);
  const { authInfo, setAuthInfo } = useContext(AuthInfoContext);

  useEffect(() => {
    verifyAuth();
    setSelectedKey('3');
    toggleNavBarVisibility(false);
    return () => {
      toggleNavBarVisibility(true);
    };
  }, []);

  return (
    // <div style={{ textAlign: 'center' }}>
    //   <Paragraph>
    //     <Title level={2}>Payment Success</Title>
    //   </Paragraph>
    //   <Button>
    //     <a href="/bookings">Back to Bookings</a>
    //   </Button>
    // </div>


  <Result
    status="success"
    title="Payment Successful"
    subTitle="We've received your payment for your SteadyShuttle booking, and our little elves are now working hard to get your booking confirmed!"
    extra={[
      <a href="/bookings">
      <Button type="primary" key="console">Back to Bookings Page
      </Button></a>,
      // <Button key="buy">Buy Again</Button>,
    ]}
  />
  );
}

export default PaymentSuccess;
