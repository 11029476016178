import React, { useEffect, createContext, useContext, useState, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Layout, Menu, Spin, Typography, List, Modal, Form, Input, Button, DatePicker, Radio, Divider, Table, Checkbox, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { AuthContext, NavBarContext, AuthInfoContext } from '../App';
import { UserOutlined, UserAddOutlined, SyncOutlined, UserDeleteOutlined, CheckCircleOutlined, ExclamationCircleOutlined,  } from '@ant-design/icons';
const DEV_ENDPOINT = 'http://localhost:5051';
const PROD_ENDPOINT = 'https://api.steadyshuttle.com';
const BACKEND_ENDPOINT = process.env.NODE_ENV === 'development' ? DEV_ENDPOINT : PROD_ENDPOINT;
const { Title, Text, Paragraph } = Typography;

function Passengers() {
  const navigate = useNavigate();
  const {isAuthenticated, setIsAuthenticated, authFailReason, setAuthFailReason, verifyAuth} = useContext(AuthContext);
  const {toggleNavBarVisibility, setSelectedKey, selectedKey} = useContext(NavBarContext);
  const { authInfo, setAuthInfo } = useContext(AuthInfoContext);

  const [passengers, setPassengers] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedPassenger, setSelectedPassenger] = useState(null);
  const [formSubmissionLoading, setFormSubmissionLoading] = useState(false);
  const formRef = useRef(null);


  const fetchPassengers = () => {
    if (process.env.NODE_ENV === 'development') {
    console.log('fetching passengers with authInfo:', authInfo)
    }
    axios.get(BACKEND_ENDPOINT + '/api/passengers', {
      headers: {
        cid: authInfo.cid,
        sess: authInfo.session_key,
      },
    })
    .then((response) => {
      // console.log(response)
      const data = response.data.passengers.map((passenger) => ({
        ...passenger,
        key: passenger._id,
      }));
      // console.log(data)
      setPassengers(response.data.passengers);
      setTableData(data);
    })
    .catch((error) => {
      console.error('Error fetching passengers:', error);
    });
  };
  
  const handleAddPassenger = async (values) => {
    setFormSubmissionLoading(true);
    try {
      const formattedValues = {
        ...values,
        passportExpiry: moment(values.passportExpiry).format('YYYY-MM-DD'),
      };
      const response = await axios.post(BACKEND_ENDPOINT + '/api/passengers', formattedValues, {
        headers: {
          cid: authInfo.cid,
          sess: authInfo.session_key,
        },
      });
      formRef.current.resetFields();
      fetchPassengers();
      setIsModalVisible(false);
      setFormSubmissionLoading(false);
    } catch (error) {
      console.error('Error adding passenger:', error);
      console.log(error.response.data.error)
      setFormSubmissionLoading(false);
    }
  };
  

  useEffect(() => {
    verifyAuth();
    setSelectedKey('2');
    toggleNavBarVisibility(true);
    fetchPassengers();
    return () => {
      toggleNavBarVisibility(true);
    };
  }, []);

  // const renderPassengerItem = (passenger) => {
  //   return (
  //     <List.Item>
  //       <List.Item.Meta
  //         title={passenger.name}
  //         description={passenger.email}
  //       />
  //     </List.Item>
  //   );
  // };


  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Contact',
      dataIndex: 'contact',
      key: 'contact',
    },
    {
      title: 'Passport',
      dataIndex: 'passport',
      key: 'passport',
    },
    {
      title: 'Expiry',
      dataIndex: 'expiry',
      key: 'expiry',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      render: (text) => (text === 1 ? 'Male' : 'Female'), //assuming 1 for male and 2 for female
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button onClick={() => handleDelete(record._id)}>Delete</Button>
      ),
    },
  ];
  
  const handleDelete = (id) => {
    // filter passenger from passengers based on matching id
    setSelectedPassenger(passengers.filter((passenger) => passenger._id === id)[0])
    setIsDeleteModalVisible(true)
  };

  const handleDeleteConfirm = () => {
    console.log(`Deleting passenger ${selectedPassenger.name} (${selectedPassenger.passport})`);

    // message.info('Deleting passenger..');
    axios.delete(BACKEND_ENDPOINT + '/api/passengers/' + selectedPassenger._id, {
      headers: {
        cid: authInfo.cid,
        sess: authInfo.session_key,
      },
    })
    .then((response) => {
      // console.log(response)
      fetchPassengers();
      message.success('Passenger deleted successfully!');
    })
    .catch((error) => {
      console.error('Error deleting passenger:', error);
    });

    setIsDeleteModalVisible(false);
  };
  
  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };


  return (
    <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left', height: '100%' }}>
      <div style={{ textAlign: 'left', width: '100%' }}>
        <Title level={1} style={{ marginTop: '0em' }}><UserOutlined /> Passengers</Title>
        <Button type="primary" onClick={() => setIsModalVisible(true)}><UserAddOutlined /> Add New Passenger</Button>

        <Table columns={columns} dataSource={tableData} style={{ marginTop: "1em" }}/>
        <Modal
            title={<><UserDeleteOutlined /> Confirm Deletion</>}
            // visible={isDeleteModalVisible}
            open={isDeleteModalVisible}
            onCancel={handleDeleteCancel}
            footer={[
              <Button key="delete" type="primary" danger onClick={handleDeleteConfirm}>
                Confirm Delete
              </Button>,
              <Button key="cancel" onClick={handleDeleteCancel}>
                Cancel
              </Button>,
            ]}
          >
            <Paragraph>
              Are you sure you want to delete passenger {selectedPassenger?.name} ({selectedPassenger?.passport})?
            </Paragraph>

            <Paragraph>
              <ExclamationCircleOutlined /> Note: Existing bookings with this passenger will not be affected. Pending bookings have to be cancelled separately.
            </Paragraph>
          </Modal>
        <Modal
          title={<><UserAddOutlined /> Add New Passenger</>}
          // visible={isModalVisible}
          open={isModalVisible}
          onCancel={() => {
            setIsModalVisible(false);
          }}
          footer={null}
        >
          <Paragraph>For added security, sensitive passenger details will be partially hidden after confirmation.</Paragraph>
          <Divider/>
          <Form onFinish={handleAddPassenger} ref={formRef}>
          <Form.Item
            name="fullName"
            label="Full Name (as in passport)"
            rules={[
              { required: true, message: 'Please input the passenger name.' },
              {
                pattern: /^[a-zA-Z@.', -]+$/i,
                message: 'Name can only contain letters, spaces, and the following special characters: @ . , - \'',
              }
            ]}
          >
            <Input onInput={(e) => { e.target.value = e.target.value.toUpperCase(); }} />
          </Form.Item>
          <Form.Item
            name="passportNumber"
            label="Passport Number"
            rules={[
              { required: true, message: 'Please input the passenger passport number.' },
              { pattern: /^[A-Za-z0-9]+$/, message: 'Only Latin characters and numbers are allowed.' }
            ]}
          >
            <Input 
              placeholder="Enter passport number, NOT your NRIC/FIN."
              onInput={(e) => { 
                e.target.value = e.target.value.toUpperCase(); 
              }} 
            />
          </Form.Item>
            {/* <p style={{ fontSize: '0.8em', paddingLeft: '1em'}}>Please enter your passport number, NOT your NRIC/FIN.</p> 
            <p style={{ fontSize: '0.8em', paddingLeft: '1em'}}>Double check that this value is correct.</p>  */}
          <Form.Item
            name="passportExpiry"
            label="Passport Expiry"
            rules={[
              { required: true, message: 'Please input the passenger passport expiry date.' },
              {
                pattern: /^\d{4}-\d{2}-\d{2}$/,
                message: 'Please enter a valid date in the format YYYY-MM-DD.',
              },
              {
                validator: (_, value) => {
                  const threeMonthsLater = moment().add(3, 'months');
                  if (value && moment(value, 'YYYY-MM-DD').isAfter(threeMonthsLater, 'day')) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Passport expiry must be more than 3 months into the future.');
                },
              },
            ]}
          >
            <Input placeholder="YYYY-MM-DD" />
          </Form.Item>

          <Form.Item
            name="contactNumber"
            label="Contact Number"
            rules={[
              { required: true, message: 'Please input the passenger contact number.' },
              {
                pattern: /^[89]\d{7}$/,
                message: 'Contact number must be 8 digits starting with the number 8 or 9.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="gender"
            label="Gender"
            rules={[{ required: true, message: 'Please select the passenger gender.' }]}
          >
            <Radio.Group>
              <Radio value="1">Male</Radio>
              <Radio value="0">Female</Radio>
            </Radio.Group>
          </Form.Item>
          <div className='block'>
          <Text style={{color:'black'}}>Important: Please ensure that all the details strictly match your passport. Your passport serves as your ticket, and will be verified by staff at the departure gate.</Text>
          </div>
          <div>
          <Text style={{color:'red', fontWeight: 'bold'}}> You will be denied boarding if the details do not exactly match your passport.</Text>
          </div>
            <Divider/>
            <Form.Item
            >
              <Checkbox required>
              I hereby consent to the collection and use of my personal data by SteadyShuttle for the purpose of booking train tickets and its related services, in accordance with the PDPA.
              </Checkbox>
            </Form.Item>
            <Form.Item
            >
              <Checkbox required>
              I have confirmed that all the details entered are correct, and understand that I am fully responsible if I am denied boarding due to incorrectly entered details.
              </Checkbox>
            </Form.Item>
            <Divider/>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={formSubmissionLoading} style={{ marginRight: 8 }}>
                {
                  formSubmissionLoading ?
                  <>
                  <SyncOutlined spin /> Loading
                  </>
                  :
                  <>
                  <CheckCircleOutlined /> Confirm Passenger Details
                  </>
                }
              </Button>
          </Form.Item>
        </Form>

        </Modal>
      </div>
    </div>
    )
  }
  
  export default Passengers;